import React from 'react'

const SVGArrow = ({ textColor }) => {
  const svgStyle = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeMiterlimit: '10',
  }

  const pathStyle1 = {
    fill: textColor,
    fillRule: 'nonzero',
  }

  const pathStyle2 = {
    fill: 'none',
    fillRule: 'nonzero',
    stroke: textColor,
    strokeWidth: `9.21px`,
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 274 274"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={svgStyle}
    >
      <path
        d="M136.956,0c-75.643,0 -136.956,61.319 -136.956,136.956c0,75.644 61.313,136.956 136.956,136.956c75.638,0 136.957,-61.312 136.957,-136.956c0,-75.637 -61.319,-136.956 -136.957,-136.956m0,9.212c70.438,0 127.744,57.307 127.744,127.744c0,70.444 -57.306,127.75 -127.744,127.75c-70.443,0 -127.743,-57.306 -127.743,-127.75c0,-70.437 57.3,-127.744 127.743,-127.744"
        style={pathStyle1}
      />
      <path d="M161.666,59.9l-77.056,77.056l77.056,77.056" style={pathStyle2} />
    </svg>
  )
}

export default SVGArrow
