/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import FramedPhoto from './FramedPhoto'
import MarkdownGallery from './MarkdownGallery'
import PhotoGallery from './PhotoGallery'
import Video from './Video'

const MediaTypeSelector = ({ media, textColor }) => {
  console.log(media)
  if (media && media.type !== undefined) {
    return media.type === 'framedphoto' ? (
      <FramedPhoto media={media} />
    ) : media.type === 'markdowngallery' ? (
      <MarkdownGallery media={media} textColor={textColor} />
    ) : media.type === 'photogallery' && media.slide !== undefined ? (
      <PhotoGallery media={media} textColor={textColor} />
    ) : media.type === 'video' ? (
      <Video media={media} />
    ) : null
  }
  return null
}

MediaTypeSelector.propTypes = {
  media: PropTypes.object,
  textColor: PropTypes.string,
}

export default MediaTypeSelector
