/* eslint-disable react/prop-types */
import React from 'react'

const inactiveSpan = {
  color: 'rgba(0, 0, 0, 0.5)',
}

const Dots = ({ index, count, go, textColor }) => (
  <div className="dots text-center">
    {Array.from(Array(count), (_, i) => i).map(i => (
      <button
        type="button"
        key={i}
        disabled={i === index}
        onClick={() => go(i)}
        className="px-1"
      >
        {i === index ? (
          <span className={`text-${textColor}`}>⬤</span>
        ) : (
          <span style={inactiveSpan}>⬤</span>
        )}
      </button>
    ))}
  </div>
)

export default Dots
