import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import Markdown from 'react-markdown'
import SVGButton from './SVG/SVGButton'
import { HTMLContent } from './Content'

const CardPattern = require('../../static/img/Patterns/cardpattern.png')

const PerformanceMeasureCards = ({ performancemeasurecards }) => (
  <ul className="flex flex-wrap justify-center pt-8 sm:pt-10 md:pt-12 lg:mx-20 xl:mx-4 2xl:mx-20 ">
    {performancemeasurecards.map(card => (
      <li
        id={card.title.replace(/ /g, '_').toLowerCase()}
        className="xl:p-3 pb-8 w-full lg:w-1/2 xl:w-1/3 text-center flex flex-col items-center"
        key={v4()}
      >
        <div
          className="w-card overflow-hidden shadow-lg text-white flex-auto bg-cover"
          style={{ backgroundImage: `url(${CardPattern})` }}
        >
          <div className="flex flex-col h-full justify-between">
            <div className={`px-10 pb-8 ${card.body ? 'pt-8' : 'pt-16'}`}>
              <h2 className="text-2xl leading-snug font-header mb-1 uppercase">
                {card.title}
              </h2>
              <h3 className="font-header font-light">{card.subheading}</h3>
            </div>
            {card.body ? <hr className="h-1/2 w-48 bg-white mx-auto" /> : null}
            <div className="font-body mx-auto px-16 py-8">
              <Markdown
                className="markdown-card text-left"
                source={card.body}
              />
            </div>
            <div className={`${card.body ? 'pb-8' : 'pb-24'}`}>
              <SVGButton
                label={card.linklabel}
                to={card.link}
                bgColor="white"
                bgColorHover="#189CD8"
                textColor="#189CD8"
                textColorHover="white"
              />
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
)

PerformanceMeasureCards.propTypes = {
  performancemeasurecards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.body,
      link: PropTypes.link,
      linklabel: PropTypes.linklabel,
    })
  ),
}

export default PerformanceMeasureCards
