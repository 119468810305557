/* eslint-disable react/prop-types */
import React from 'react'
import Markdown from 'react-markdown'
import useStep from './useStep'
import Navigation from './Navigation'
import Image from '../../Image'

const Carousel = ({ initialStep, media, textColor }) => {
  const { step, navigation, index, isPaused } = useStep({
    initialStep,
    // re-add to useStep - autoAdvanceDuration: 0, Paused in state default from within useStep
    steps: media.slide || 0,
  })
  return (
    <>
      <Navigation
        textColor={textColor}
        isPaused={isPaused}
        index={index}
        count={
          media.markdown
            ? media.markdown.length
            : media.slide
            ? media.slide.length
            : 0
        }
        {...navigation}
      >
        {media.type === 'markdowngallery' ? (
          <div className="font-body relative container w-5/6 text-white py-10">
            <Markdown
              className="w-full py-6 markdown-section"
              source={step.markdown}
            />
            {/* {!isPaused && (
              <Progress step={index} duration={autoAdvanceDuration} />
            )} */}
          </div>
        ) : (
          <div className="relative border-16 border-black bg-black">
            {step !== undefined ? (
              <>
                <Image imageInfo={step} />
                <div
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                  className="font-body w-full relative lg:absolute lg:bottom-0 text-center"
                >
                  <Markdown
                    className="w-full py-2 text-white"
                    source={step.caption}
                  />
                </div>
              </>
            ) : null}
            {/* {!isPaused && (
              <Progress step={index} duration={autoAdvanceDuration} />
            )} */}
          </div>
        )}
      </Navigation>
    </>
  )
}

export default Carousel
