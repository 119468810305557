import React from 'react'
import PropTypes from 'prop-types'
import Carousel from './Gallery/Carousel'

const PhotoGallery = ({ media, textColor }) => (
  <section className="w-full">
    <Carousel initialStep={0} media={media} textColor={textColor} />
  </section>
)

PhotoGallery.propTypes = {
  media: PropTypes.object,
  textColor: PropTypes.string,
}

export default PhotoGallery
