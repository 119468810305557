import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import SVGhr from './SVG/SVGhr'
import Image from './Image'

const ListItems = ({ listitems }) => {
  const indexLength = listitems.length
  return (
    <ul className="flex flex-wrap justify-center">
      {listitems.map((item, index) => (
        <li className="w-full">
          <div
            className=" flex flex-col lg:flex-row py-10 w-full lg:max-w-full"
            // Code to alternate lists items
            // index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
            key={v4()}
          >
            <div className="h-full overflow-hidden w-1/2 m-auto lg:m-0 sm:w-1/3 lg:w-1/5">
              <Image imageInfo={item.image} />
            </div>
            <div className="leading-normal w-full lg:w-4/5 mt-2 flex flex-col self-start">
              <div className="text-center lg:text-left lg:-ml-8 lg:mb-6 xl:-ml-16 listitem-container">
                <h2 className="ml-4 text-purple text-xl font-header my-2 listitem-text">
                  {item.title}
                </h2>
              </div>
              <p className="font-body text-black-darker ml-3 text-base">
                {item.body}
              </p>
            </div>
          </div>
          <div>{indexLength !== index + 1 ? <SVGhr /> : null}</div>
        </li>
      ))}
    </ul>
  )
}

ListItems.propTypes = {
  listitems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export default ListItems
