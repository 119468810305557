import React from 'react'
import PropTypes from 'prop-types'
import MediaTypeSelector from './Multimedia/MediaTypeSelector'

const Highlight = ({ highlight }) => {
  console.log(highlight.highlightsection[0])
  return (
    <div className="flex flex-col items-center">
      {highlight.title ? (
        <h1 className="text-3xl uppercase font-header mb-2">
          {highlight.title}
        </h1>
      ) : null}
      {highlight.body ? (
        <p className="font-body mb-10">{highlight.body}</p>
      ) : null}
      {highlight.highlightsection && (
        <MediaTypeSelector
          media={highlight.highlightsection[0]}
          textColor="white"
        />
      )}
    </div>
  )
}

Highlight.propTypes = {
  highlight: PropTypes.object,
}

export default Highlight
