/* eslint-disable react/prop-types */
import React from 'react'
import SVGArrow from '../../SVG/SVGArrow'
import Dots from './Dots'

const Navigation = ({
  children,
  isPaused,
  index,
  count,
  next,
  previous,
  go,
  play,
  pause,
  textColor,
}) => (
  <div className="navigation">
    <div className="flex">
      <button type="button" onClick={previous}>
        <div className="w-8">
          <SVGArrow textColor={textColor} />
        </div>
      </button>
      <div className={`w-full p-4 text-${textColor}`}>{children}</div>
      <button type="button" onClick={next}>
        <div className="rotate-180 w-8">
          <SVGArrow textColor={textColor} />
        </div>
      </button>
    </div>
    <div className="mx-auto">
      <div>
        <Dots textColor={textColor} index={index} count={count} go={go} />
      </div>
      {/* <div className="float-right text-white">
        {isPaused ? (
          <button className={`text-${textColor}`} type="button" onClick={play}>
            Play ►
          </button>
        ) : (
          <button className={`text-${textColor}`} type="button" onClick={pause}>
            Pause ❙❙
          </button>
        )}
      </div> */}
    </div>
  </div>
)

export default Navigation
