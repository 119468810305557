import React from 'react'
import PropTypes from 'prop-types'
import Carousel from './Gallery/Carousel'

const MarkdownGallery = ({ media, textColor }) => (
  <section>
    <Carousel initialStep={0} media={media} textColor={textColor} />
  </section>
)

MarkdownGallery.propTypes = {
  media: PropTypes.object,
  textColor: PropTypes.string,
}

export default MarkdownGallery
