import React from 'react'

const svgContainer = {
  position: 'relative',
  height: 0,
  width: '100%',
  padding: 0,
  paddingBottom: '1.53%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinecap: 'round',
  strokeMiterlimit: '10',
}

const pathStyle = {
  fill: 'none',
  fillRule: 'nonzero',
  stroke: '#b1bab3',
  strokeWidth: '3.9px',
  strokeDasharray: '0,13.07',
}

const SVGhr = () => (
  <div style={svgContainer}>
    <svg
      viewBox="0 0 1501 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={svgStyle}
    >
      <path d="M33.847,11.362l1432.53,0" style={pathStyle} />
    </svg>
  </div>
)

export default SVGhr
