import React from 'react'
import PropTypes from 'prop-types'

const Video = ({ media }) => (
  <div className="border-black border-16 relative w-full max-w-4xl h-0 video-component">
    <iframe
      src={`https://www.youtube.com/embed/${media.videoid}`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
      className="absolute top-0 left-0 w-full h-full"
    />
  </div>
)

Video.propTypes = {
  media: PropTypes.object,
}

export default Video
