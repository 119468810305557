import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ListItems from '../components/ListItems'
import PerformanceMeasureCards from '../components/PerformanceMeasureCards'
import Highlight from '../components/Highlight'
import DiscoverMore from '../components/DiscoverMore'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import { HTMLContent } from '../components/Content'
import Container from '../components/Container'
import Section from '../components/Section'

export const ExplorePageTemplate = ({
  bannerimage,
  theme,
  title,
  sectionwithlistitems,
  mediasection,
  performancemeasuresection,
  discovermore,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="explore" label="Explore" />
    <Container className="py-10 sm:py-12">
      <h2 className="font-header text-pink text-3xl uppercase text-center">
        {sectionwithlistitems.title}
      </h2>
      <ListItems listitems={sectionwithlistitems.listitems} />
    </Container>
    <Container className="py-12 sm:py-16" outerClassName="bg-teal text-white">
      <Highlight highlight={mediasection} />
    </Container>
    <Container className="py-16 sm:py-20">
      <Section
        titleStyle="text-center text-pink"
        content={performancemeasuresection}
      />
      <PerformanceMeasureCards
        performancemeasurecards={
          performancemeasuresection.performancemeasurecards
        }
        contentComponent={HTMLContent}
      />
    </Container>
    <Container
      outerClassName="bg-black-discovermore text-center"
      className="pb-2 py-12 sm:py-16"
    >
      <DiscoverMore content={discovermore} />
    </Container>
  </>
)

ExplorePageTemplate.propTypes = {
  bannerimage: PropTypes.object,
  theme: PropTypes.string,
  title: PropTypes.string,
  mediasection: PropTypes.object,
  sectionwithlistitems: PropTypes.object,
  performancemeasuresection: PropTypes.object,
  discovermore: PropTypes.object,
}

const ExplorePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <ExplorePageTemplate
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        sectionwithlistitems={frontmatter.sectionwithlistitems}
        mediasection={frontmatter.mediasection}
        performancemeasuresection={frontmatter.performancemeasuresection}
        discovermore={frontmatter.discovermore}
      />
    </Layout>
  )
}

ExplorePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ExplorePage

export const pageQuery = graphql`
  query ExplorePageTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionwithlistitems {
          title
          listitems {
            title
            body
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        mediasection {
          title
          body
          highlightsection {
            type
            videoid
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slide {
              caption
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        performancemeasuresection {
          title
          body
          performancemeasurecards {
            title
            subheading
            body
            link
            linklabel
          }
        }
        discovermore {
          title
          body
          linklabel
          link
        }
      }
    }
  }
`
