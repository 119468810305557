import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import Image from '../Image'

const FramedPhoto = ({ media }) => (
  <div className="border-16  border-black relative">
    <Image imageInfo={media} />
    <div
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
      className="w-full relative text-center"
    >
      <Markdown className="text-white py-2 px-4" source={media.caption} />
    </div>
  </div>
)

FramedPhoto.propTypes = {
  media: PropTypes.object,
}

export default FramedPhoto
